import axios from "./dcpAxiosInstance";

const user = {
  namespaced: true,
  state: () => ({
    token: window.localStorage.getItem("token"),
    userData: JSON.parse(localStorage.getItem("userData")),
    userPolling: null,
    blockedUsers: [],
  }),
  mutations: {
    setToken(state, tokenValue) {
      state.token = tokenValue;

      window.localStorage.setItem("token", tokenValue);
    },
    setUserData(state, userData) {
      state.userData = userData;

      window.localStorage.setItem("userData", JSON.stringify(userData));
    },
    setUserPolling(state, polling) {
      if (polling === undefined) {
        clearInterval(state.userPolling);
        state.userPolling = null;
      } else {
        state.userPolling = polling;
      }
    },
    setBlockedUsers(state, users) {
      state.blockedUsers = users;
    },
  },
  actions: {
    loginUser({ commit, dispatch }, { email, password }) {
      return axios
        .post("/sessions", {
          email,
          password,
        })
        .then(function (response) {
          const data = response.data;
          const authToken = data.authorization;

          commit("setToken", authToken);
          commit("setUserData", data);

          dispatch("startPollingUserData");

          return response;
        })
        .catch(function (error) {
          return error.response;
        });
    },
    logoutUser({ commit, dispatch }) {
      return axios.delete("/sessions").then(function (response) {
        commit("setToken", "");
        commit("setUserData", "");

        dispatch("stopPollingUserData");

        return response;
      });
    },
    updateUser(_, formData) {
      return axios
        .put("/account.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          return response;
        });
    },
    registerUser(_, formData) {
      return axios
        .post("/account", formData)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error.response;
        });
    },
    getUserData({ commit }) {
      return axios.get("/sessions").then(function (response) {
        const data = response.data;
        const authToken = data.authorization;

        commit("setToken", authToken);
        commit("setUserData", data);

        return response;
      });
    },
    userForgotPassword(_, formData) {
      return axios
        .post("/users/password", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          return response;
        })
        .catch(function (error) {
          console.log(error.response);

          return error.response;
        });
    },
    startPollingUserData({ commit, dispatch }) {
      const polling = setInterval(() => {
        dispatch("getUserData");
      }, 60000);

      commit("setUserPolling", polling);
    },
    stopPollingUserData({ commit }) {
      commit("setUserPolling");
    },
    deleteUser({ commit, dispatch }, userId) {
      return axios
        .delete(`/account`, {
          params: { user_id: userId },
        })
        .then(function (response) {
          commit("setToken", "");
          commit("setUserData", "");

          dispatch("stopPollingUserData");

          return response;
        });
    },
    blockUser(_, userId) {
      return axios
        .post("/blocks", {
          id: userId,
        })
        .then(function (response) {
          return response;
        });
    },
    unblockUser(_, userId) {
      return axios.delete(`/blocks/${userId}`).then(function (response) {
        return response;
      });
    },
    getBlockedUsers({ commit }) {
      return axios.get("/blocks.json").then(function (response) {
        const data = response.data;

        commit("setBlockedUsers", data.blocked_users);

        return response;
      });
    },
  },
  getters: {
    isTokenSet(state) {
      return state.token ? true : false;
    },
    jwtToken(state) {
      return state.token;
    },
    userData(state) {
      return state.userData;
    },
    currentUserId(state) {
      return state.userData.user_id;
    },
    isUserAdmin(state) {
      return state.userData.is_admin;
    },
    totalUnreadMessages(state) {
      return state.userData.total_messages_unread_count;
    },
    blockedUsers(state) {
      return state.blockedUsers;
    },
  },
};

export default user;
