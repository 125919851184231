import axios from "./dcpAxiosInstance";

const post = {
    namespaced: true,
    state: () => ({
        posts: [],
        showNewPostForm: false,
    }),
    mutations: {
        setPosts(state, posts) {
            state.posts = posts;
        },
        setShowPostsForm(state, bool) {
            state.showNewPostForm = bool;
        },
    },
    actions: {
        getPosts({ commit }, paramValues) {
            const parameters = {};

            if (paramValues) {
                if (paramValues.searchValue) {
                    parameters.search = paramValues.searchValue;
                }

                if (paramValues.limit) {
                    parameters.limit = paramValues.limit;
                }
            }

            return axios
                .get("/posts.json", { params: parameters })
                .then(function (response) {
                    const data = response.data;

                    commit("setPosts", data.posts);

                    return response;
                })
                .catch(function (error) {
                    return error.response;
                });
        },
        sharePost(_, formData) {
            return axios
                .post("/posts", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        deletePost({ state, commit }, postId) {
            const posts = state.posts;
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object
            const postIndex = posts.findIndex((post) => post.id == postId); // find index of object with postId

            clonedPosts.splice(postIndex, 1);

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios.delete(`/posts/${postId}`).catch(function () {
                commit("setPosts", posts); // reset the posts if axios request failed
            });
        },
        reportPost(_, postId) {
            return axios
                .post("/reported_items.json", {
                    report_item: {
                        item_id: postId,
                        item_type: "Post",
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        likePost({ state, commit }, postId) {
            const posts = state.posts;
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object
            const postIndex = posts.findIndex((post) => post.id == postId); // find index of object with postId

            clonedPosts[postIndex].likes = clonedPosts[postIndex].likes + 1;
            clonedPosts[postIndex].current_user_likes_this = true;

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios.post(`/posts/${postId}/likes`).catch(function () {
                commit("setPosts", posts); // reset the posts if axios request failed
            });
        },
        unlikePost({ state, commit }, postId) {
            const posts = state.posts;
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object
            const postIndex = clonedPosts.findIndex(
                (post) => post.id == postId
            ); // find index of object with postId

            clonedPosts[postIndex].likes = clonedPosts[postIndex].likes - 1;
            clonedPosts[postIndex].current_user_likes_this = false;

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios.delete(`/posts/${postId}/likes`).catch(function () {
                commit("setPosts", posts); // reset the posts if axios request failed
            });
        },
        postComment(_, commentData) {
            return axios
                .post("/comments/", {
                    comment: {
                        comment: commentData.commentText,
                        commentable_type: "Post",
                        commentable_id: commentData.postId,
                    },
                })
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return error;
                });
        },
        deleteComment({ state, commit }, { postId, commentId }) {
            const posts = state.posts;
            const postIndex = posts.findIndex((post) => post.id == postId); // find index of object with postId
            const commentIndex = posts[postIndex].comments.findIndex(
                (comment) => comment.id == commentId
            ); // find index of comment with commentId
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object

            clonedPosts[postIndex].comments.splice(commentIndex, 1);

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios.delete(`/comments/${commentId}`).catch(() => {
                commit("setPosts", posts); // reset the posts if axios request failed
            });
        },
        likeComment({ state, commit }, { postId, commentId }) {
            const posts = state.posts;
            const postIndex = posts.findIndex((post) => post.id == postId); // find index of object with postId
            const commentIndex = posts[postIndex].comments.findIndex(
                (comment) => comment.id == commentId
            ); // find index of comment with commentId
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object

            clonedPosts[postIndex].comments[commentIndex].likes =
                clonedPosts[postIndex].comments[commentIndex].likes + 1;
            clonedPosts[postIndex].comments[
                commentIndex
            ].current_user_likes_this = true;

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios
                .post(`/posts/${postId}/comments/${commentId}/likes`, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .catch(() => {
                    commit("setPosts", posts); // reset the posts if axios request failed
                });
        },
        unlikeComment({ state, commit }, { postId, commentId }) {
            const posts = state.posts;
            const postIndex = posts.findIndex((post) => post.id == postId); // find index of object with postId
            const commentIndex = posts[postIndex].comments.findIndex(
                (comment) => comment.id == commentId
            ); // find index of comment with commentId
            let clonedPosts = JSON.parse(JSON.stringify(posts)); // clone object

            clonedPosts[postIndex].comments[commentIndex].likes =
                clonedPosts[postIndex].comments[commentIndex].likes - 1;
            clonedPosts[postIndex].comments[
                commentIndex
            ].current_user_likes_this = false;

            commit("setPosts", clonedPosts); // set new post data before axios request for better reactivity

            axios
                .delete(`/posts/${postId}/comments/${commentId}/likes`)
                .catch(() => {
                    commit("setPosts", posts); // reset the posts if axios request failed
                });
        },
        reportComment(_, commentId) {
            return axios
                .post("/reported_items.json", {
                    report_item: {
                        item_id: commentId,
                        item_type: "Comment",
                    },
                })
                .then(function (response) {
                    return response;
                });
        },
        openNewPostForm({ commit }) {
            commit("setShowPostsForm", true);
        },
        closeNewPostForm({ commit }) {
            commit("setShowPostsForm", false);
        },
    },
    getters: {
        posts(state) {
            return state.posts;
        },
        postsAmount(state) {
            return state.posts.length;
        },
        showNewPostForm(state) {
            return state.showNewPostForm;
        },
    },
};

export default post;
