const favourites = {
  namespaced: true,
  state: {
    favourites: [],
  },
  getters: {
    favourites(state) {
      return state.favourites;
    },
    isFavouriteInList: (state) => (favourite) => {
      return state.favourites.findIndex((f) => f.id === favourite.id) >= 0;
    },
    favouritesCount: (state) => {
      return state.favourites.length;
    },
  },
  mutations: {
    updateFavourites(state, favourites) {
      state.favourites = favourites;
    },
  },
  actions: {
    removeFavourite({ state, commit }, favourite) {
      const favourites = state.favourites;
      const idx = favourites.findIndex((f) => f == favourite);

      if (idx >= 0) {
        favourites.splice(idx, 1);

        commit("updateFavourites", favourites);
      }
    },
    addFavourite({ state, commit }, favourite) {
      let favourites = state.favourites;
      favourites.push(favourite);

      commit("updateFavourites", favourites);
    },
  },
};

export default favourites;
