import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import hubbdata from "./hubbdata";
import favourites from "./favourites";
import vips from "./vips";
import user from "./user";
import post from "./post";
import group from "./group";
import chat from "./chat";
import survey from "./survey";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        hubbdata,
        favourites,
        vips,
        user,
        post,
        group,
        chat,
        survey,
    },
    state: {
        loaderStatus: {
            visability: false,
            text: "",
        },
    },
    mutations: {
        setloaderStatus(state, loaderStatusData) {
            state.loaderStatus = loaderStatusData;
        },
    },
    actions: {
        updateLoaderStatus({ commit }, loaderData) {
            commit("setloaderStatus", {
                visability: loaderData.visability,
                text: loaderData.text,
            });
        },
    },
    getters: {
        loaderStatus(state) {
            return state.loaderStatus;
        },
    },
    plugins: [new VuexPersistence({ modules: ["vips", "favourites"] }).plugin],
});

export default store;
