<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        tabindex="0"
        class="header__nav-list-item"
      >
        <v-list-item-icon class="header__nav-icon">
          <v-img src="@/assets/icons/settings-nav.svg" width="20px" contain />
        </v-list-item-icon>
        {{ translations.home.labels.settings }}
      </button>
    </template>

    <v-card>
      <v-toolbar class="settings__toolbar" dark flat>
        <button type="button" @click="close" class="settings__close-btn">
          <v-icon color="white">mdi-close</v-icon>
          <span class="sr-only">
            {{ translations.settings.text.close }}
          </span>
        </button>

        <v-toolbar-title class="settings__toolbar-heading" tag="h2">
          {{ translations.settings.title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-container>
        <ul class="settings__list" tag="ul">
          <li class="settings__list-item flex-column align-start pa-0" tag="li">
            <label class="settings__label" for="fonts" dark>
              {{ translations.settings.text.changedefault }}
            </label>

            <v-switch
              v-model="openDyslexic"
              id="fonts"
              name="fonts"
              class="settings__input d-block w-100"
              width="100%"
              :label="`OpenDyslexic`"
              @change="fontChangeHandler(openDyslexic)"
              solo
              color="green"
              hide-details
            ></v-switch>
          </li>

          <li class="settings__list-item flex-column align-start pa-0" tag="li">
            <label class="settings__label" for="langs">
              {{ translations.settings.text.languages }}
            </label>

            <v-select
              v-model="selectedLang"
              :items="langs"
              menu-props="auto"
              id="langs"
              name="langs"
              background-color="green"
              item-text="name"
              item-value="code"
              @change="languageSelectHandler(selectedLang)"
              hide-details
              class="settings__language-select mt-4"
              flat
              filled
              item-color="black"
              append-icon="mdi-chevron-down"
            ></v-select>
          </li>
        </ul>

        <v-alert
          close-text="Close Alert"
          color="green"
          dismissible
          icon="mdi-alert-circle"
          @input="DismissFontAlert()"
          dark
          v-if="fontAlert"
        >
          <span v-if="openDyslexic" class="settings__alert-text">
            {{ translations.settings.text.textopen }}
          </span>
          <span v-else class="settings__alert-text">
            {{ translations.settings.text.textdefault }}
          </span>
        </v-alert>

        <v-alert
          close-text="Close Alert"
          color="green"
          dismissible
          icon="mdi-alert-circle"
          @input="DismissAlert()"
          dark
          v-if="alert"
        >
          <span class="settings__alert-text">
            {{ translations.settings.text.languageset }}
            {{ currentLang }}
          </span>
        </v-alert>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Settings",
  data: () => ({
    drawer: false,
    selectedLang: localStorage.getItem("current_lang"),
    dialog: false,
    fontAlert: false,
    alert: false,
    openDyslexic: false,
  }),
  computed: {
    ...mapGetters("hubbdata", ["translations"]),
    ...mapGetters("hubbdata", { langs: "getLangs" }),

    currentLang() {
      let dictionary = {
        en: "English",
        sq: "Albanian",
        am: "Amharic",
        ar: "Arabic",
        fa_AF: "Dari",
        fr: "French",
        ps: "Pashto",
        pt_PT: "Portuguese",
        ti: "Tigrinya",
      };

      return dictionary[this.selectedLang];
    },
  },
  methods: {
    ...mapActions("hubbdata", ["updateLang", "initDB", "updateFont"]),
    languageSelectHandler(selectedLang) {
      this.updateLang(selectedLang).then(() => {
        this.initDB();
      });

      this.alert = true;
      // send a global emit back to Header.vue with the newly selected language
      this.$bus.emit("language-changed-from-settings-page", this.selectedLang);
    },
    fontChangeHandler(font) {
      localStorage.setItem("enable_font_change", font);
      this.updateFont(font);
      this.fontAlert = true;
    },
    DismissAlert() {
      this.alert = false;
    },
    DismissFontAlert() {
      this.fontAlert = false;
    },
    close() {
      let settingsLink = document.getElementsByClassName(
        "header__nav-list-item"
      );

      settingsLink[0].classList.remove("v-item--active", "v-list-item--active");

      this.dialog = false;
    },
  },
  mounted() {
    if (localStorage.getItem("enable_font_change") === "true") {
      this.openDyslexic = true;
    } else {
      this.openDyslexic = false;
    }

    /// listening for language change emit coming form Header.vue
    /// we're passing the 'language' and then setting is as a selected one
    this.$bus.$on("language-changed-from-header", (language) => {
      this.selectedLang = language;
    });
  },
  beforeDestroy() {
    // stop listening for the emit coming from Header.vue
    this.$bus.$off("language-changed-from-header");
  },
};
</script>

<style lang="scss" scoped>
.settings {
  height: 100%;

  &__toolbar {
    background: $black !important;
    border-bottom: 5px solid $green;
  }

  &__toolbar-heading {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0 !important;
  }

  &__label {
    font-size: 1rem;
    font-weight: 700;
  }

  &__input {
    ::v-deep {
      label {
        color: $black;
      }
    }
  }

  &__alert-text {
    color: $black;
    font-size: 1rem;

    .v-icon {
      color: $black;
    }
  }

  &__language-select {
    ::v-deep {
      &.v-select {
        border-radius: 10px;

        &__slot::before {
          display: none;
        }

        &.v-input--is-focused {
          outline: 2px solid $black;
          outline-offset: 2px;
        }

        .v-input__slot {
          padding: 2px 20px;

          &:before,
          &:after {
            content: none !important;
          }
        }

        .v-select__selection {
          font-weight: 500;
        }

        .v-icon {
          color: $black !important;
          font-size: 1.88rem;
        }
      }
    }
  }

  &__close-btn {
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;

    &:hover,
    &:focus {
      outline: 2px solid;
      outline-offset: 2px;
    }
  }

  &__list {
    padding: 0 !important;
    list-style-type: none;
  }

  &__list-item {
    min-height: unset !important;
    margin-bottom: 32px;
  }

  ::v-deep
    .v-input--switch.v-input--is-focused
    .v-input--selection-controls__ripple {
    outline: 2px solid $black;
    outline-offset: 2px;
  }

  ::v-deep .v-select.v-input--is-focused .v-input__control {
    outline: 2px solid $black;
    outline-offset: 2px;
  }
}

.v-alert {
  border-radius: 10px;
  margin: 10px 0px 10px 10px !important;
  margin-left: 0px !important;
  width: 100%;

  ::v-deep {
    .v-btn {
      &:focus {
        outline: 2px solid $black;
      }
    }

    .v-icon {
      color: $black;
    }
  }
}

.header {
  &__nav-list-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 600;
    color: $white !important;
    word-break: break-word;
    padding: 0 8px !important;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:focus {
      outline: 2px solid $white;

      &::before {
        background-color: $white;
        opacity: 0.12;
      }
    }
  }

  &__nav-icon {
    margin-right: 10px !important;
    margin-left: 0 !important;

    .v-image {
      flex: unset !important;
    }
  }
}
</style>
