import axios from "./dcpAxiosInstance";

const group = {
    namespaced: true,
    state: () => ({
        groups: [],
    }),
    mutations: {
        setGroups(state, groups) {
            state.groups = groups;
        },
    },
    actions: {
        getGroups({ commit }) {
            axios.get("/groups/mine.json").then(function (response) {
                const data = response.data;
                let groups = data.groups.filter(
                    (group) => group.name != "Everyone"
                );

                commit("setGroups", groups);
            });
        },
    },
    getters: {
        groups(state) {
            return state.groups;
        },
    },
};

export default group;
