<template>
  <v-avatar
    v-if="avatar.display_name"
    :class="[{ 'shrink mr-2': !centered, 'd-flex mx-auto': centered }]"
    contain
    transition="scale-transition"
    :size="size"
    color="primary"
  >
    <span
      v-if="!avatar.image_url"
      class="black--text text-uppercase"
      :style="{ 'font-size': fontSize }"
    >
      {{ avatar.display_name.charAt(0) }}
    </span>

    <img
      v-if="avatar.image_url"
      :src="avatar.image_url"
      :alt="avatar.display_name.charAt(0)"
    />
  </v-avatar>
</template>

<script>
export default {
  name: "AvatarIcon",
  props: {
    avatar: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 40,
    },
    textSize: {
      type: Number,
      default: 20,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fontSize() {
      return `${this.textSize}px`;
    },
  },
};
</script>
