import axios from "axios";

const API_URL = process.env.API_URL || "https://careleaverssocialhub.test";

// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = API_URL;
console.log("Setting baseURL in hubbapi.js")


// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axios.defaults.withCredentials = true;

 axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';



export default {
  saveSurveyAnswers(data) {
      var number_only_survey_id = data.survey_id.match(/\d+/)[0]
      return axios({
        method: "post",
        url: '/simple_surveys/' + number_only_survey_id + '/simple_survey_answers',
        data: data
      }).then(response => { 
        console.log(response)
      }).catch(error => {
        setTimeout(function(){ 
        console.log(error)
      }, 10000);  
      });
  },
};

// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`
