import Vue from "vue";
import Vuetify from "vuetify/lib";
import light from "./themes/default";

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light,
    },
  },
});
