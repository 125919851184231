import axios from "axios";
import router from "../router";
import store from "./store";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL =
  process.env.API_URL || "https://southgloucestershire.secure-messaging.co.uk";

axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("token");

  config.headers.authorization = token ? token : "";

  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("store isTokenSet", store.getters["user/isTokenSet"]);
    // if any request is 401 and the user token is set meaning they are logged in then we logout the user and reroute them
    if (error.response.status == 401 && store.getters["user/isTokenSet"]) {
      console.log("reroute user", router.currentRoute.name);
      store.dispatch("user/logoutUser").then(() => {
        // if user is on any of the DCP pages reroute them to the sign in page
        if (
          router.currentRoute.name === "user_edit" ||
          router.currentRoute.name === "forgot_password" ||
          router.currentRoute.name === "chats" ||
          router.currentRoute.name === "posts" ||
          router.currentRoute.name === "surveys"
        ) {
          router.push({ name: "sign_in" });
        }
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
