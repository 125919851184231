<template>
  <a
    v-if="translations"
    href="#main-content"
    class="bypass-block"
    data-skip-link=""
    @click.prevent="focusMain"
  >
    {{ translations.bypass_block.text }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BypassBlock",
  computed: {
    ...mapGetters("hubbdata", ["translations"]),
  },
  methods: {
    focusMain() {
      console.log("hie");
      document.getElementById("main-content").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.bypass-block {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: white;
  transform: translateX(calc(-100% - 16px));
  z-index: 100;
  padding: 16px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 700;
  color: black !important;
  border: 2px solid black;
  text-decoration: none;

  &:focus {
    transform: translateX(0);
    outline: 2px solid black;
    outline-offset: 2px;
  }
}
</style>
