// import colors from 'vuetify/lib/util/colors'
export default {
  green: "#2bcb5f",
  lightGreen: "#d5f5df",
  pink: "#fb28b1",
  lightPink: "#fed4ef",
  yellow: "#fee528",
  lightYellow: "#fffad4",
  blue: "#0daff0",
  lightBlue: "#cfeffc",
  red: "#fb2b2c",
  lightRed: "#fed5d5",
  error: "#c60405",
};
